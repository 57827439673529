<template>
    <div ref="languageButtonRef"
         class="d-flex justify-content-center align-items-center"
         :class="{ 'mobile-menu-button': !props.isConverter }"
         @click="openModal">
        <template v-if="!props.isConverter">
            <img :src="asset_cdn(props.currentFiatCurrency.logo, 15)"
                 class="mr-1 border rounded-circle"
                 width="15"
                 height="15"
                 :alt="props.currentFiatCurrency.name">
            <div class="currency-code">
                {{ props.currentFiatCurrency.currency_code }}
            </div>
        </template>
    </div>
    <Teleport to="body">
        <Transition name="modal-fade">
            <FiatCurrencySearchModal v-if="showModal" />
        </Transition>
    </Teleport>
</template>

<script setup lang="ts">
    import { h, ref, watchEffect } from 'vue';
    import useTippyPopup from '../../composables/useTippyPopup';
    import FiatCurrencySearchContent from './FiatCurrencySearchContent.vue';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { useMenuStore } from '@/js/store/menu/MenuStore';
    import FiatCurrencySearchModal from '../menu/mobile-menu-footer/search/FiatCurrencySearchModal.vue';
    import { IFiatCurrencySearch } from '../menu/types/menuFooterTypes';

    interface IFiatCurrencySearchProps extends IFiatCurrencySearch {
        showModal?: boolean,
    }

    const props = withDefaults(defineProps<IFiatCurrencySearchProps>(), {
        isConverter: false,
        showModal: false,
    });

    const menuStore = useMenuStore();
    const { isMobileOrTablet } = useDeviceSize();

    const languageButtonRef = ref(null);

    const openModal = () => {
        menuStore.isCurrencySearchModalOpen = true;
    };

    const { disable, enable } = useTippyPopup(languageButtonRef, {
        content: h(FiatCurrencySearchContent, {
            fiatCurrencies: props.fiatCurrencies,
            currentFiatCurrency: props.currentFiatCurrency,
            isConverter: props.isConverter,
            isShort: true,
        }),
        placement: 'top',
        trigger: 'click',
        interactive: true,
    });

    watchEffect(() => {
        if (isMobileOrTablet.value) {
            disable();
        } else {
            enable();
        }
    });
</script>

<style scoped lang="scss">
@media (max-width: 1240px) {
    .currency-code {
        font-size: 14px;
        font-weight: 500;
    }
}
</style>
