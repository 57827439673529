<template>
    <div class="modal-container">
        <div class="close-button"
             @click="closeModal">
            &times;
        </div>
        <div>
            <SearchInput v-model="search" />
            <div class="list-container">
                <SearchListItem v-for="(listItem, index) in filteredFiatCurrencies"
                                :key="index"
                                :code="`${listItem.currency_code} - ${listItem.sign}`"
                                :link="`?currency=${listItem.currency_code}`"
                                :logo="asset_cdn(listItem.logo)"
                                :name="listItem.name" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useMenuStore } from '@/js/store/menu/MenuStore';
    import { asset_cdn } from '@/js/helpers/helpers';
    import SearchListItem from './SearchListItem.vue';
    import SearchInput from './SearchInput.vue';
    import { IFiatCurrency } from '../../types/menuFooterTypes';

    const menuStore = useMenuStore();

    const search = ref('');

    const filteredFiatCurrencies = computed<Array<IFiatCurrency>>(() => menuStore.mobileMenuFooterData.fiatCurrencies.filter((fiatCurrency: IFiatCurrency) => {
        fiatCurrency.name.toLowerCase().includes(search.value.toLowerCase());

        return fiatCurrency.name.toLowerCase().includes(search.value.toLowerCase()) || fiatCurrency.currency_code.toLowerCase().includes(search.value.toLowerCase());
    }));

    const closeModal = () => {
        menuStore.isCurrencySearchModalOpen = false;
    };
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/partials/z-index";

.modal-container {
    position: fixed;
    z-index: $navigation-modal-z-index;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    padding: 16px;
    background: $white;

    .close-button {
        width: min-content;
        margin-bottom: 8px;
        color: $steel-blue;
        cursor: pointer;
        font-size: 40px;
        line-height: 40px;
        transition: color 200ms ease;

        &:hover {
            color: $black;
        }
    }

    .list-container {
        max-height: 80vh;
        overflow-y: scroll;
    }
}
</style>
