<template>
    <div class="d-none">
        <div id="gas-price">
            <div class="d-flex flex-row align-items-center view-selector">
                <button v-for="(item, index) in gasPrices"
                        :key="item.network"
                        class="d-flex btn"
                        :class="{ 'active': index === selectedNetworkIndex }"
                        @click="selectedNetworkIndex = index">
                    <span>
                        {{ item.network.toUpperCase() }}
                    </span>
                </button>
            </div>
            <div class="row justify-content-center info gas-price mx-0 px-0">
                <a :href="`${globalChartsRoute}#gas-prices`"
                   class="col-4 px-0 text-center border-right">
                    <div class="label">
                        <translate translation-key="header.slow" />
                    </div>
                    <div class="value">
                        {{ gasPrices[selectedNetworkIndex].slow }}
                        <translate translation-key="header.gwei" />
                    </div>
                </a>
                <a :href="`${globalChartsRoute}#gas-prices`"
                   class="col-4 px-0 text-center border-right">
                    <div class="label">
                        <translate translation-key="header.standard" />
                    </div>
                    <div class="value">
                        {{ gasPrices[selectedNetworkIndex].standard }}
                        <translate translation-key="header.gwei" />
                    </div>
                </a>
                <a :href="`${globalChartsRoute}#gas-prices`"
                   class="col-4 px-0 text-center">
                    <div class="label">
                        <translate translation-key="header.fast" />
                    </div>
                    <div class="value">
                        {{ gasPrices[selectedNetworkIndex].fast }}
                        <translate translation-key="header.gwei" />
                    </div>
                </a>
            </div>
            <div class="row justify-content-center info gas-price mx-0 px-0">
                <div class="col-12 text-center px-0">
                    <div class="powered-by py-1 d-flex justify-content-center align-items-center">
                        <translate translation-key="header.poweredBy"
                                   fallback="Powered by" />&nbsp;
                        <div>{{ dataSource[selectedNetworkIndex] }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            gasPrices: {
                type: Object,
                required: true,
            },
            globalChartsRoute: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                selectedNetworkIndex: 0,
                dataSource: ['Etherscan', 'BscScan', 'PolygonScan'],
            };
        },
    };
</script>
