import { getCookieValue, setCookie } from './cookieHelpers';

const switchTheme = () => {
    const isLightMode = getCookieValue('light-theme');
    const expirationInDays = 365;

    if (isLightMode === 'disabled' || isLightMode === null) {
        setCookie('light-theme', 'enabled', expirationInDays);
    } else {
        setCookie('light-theme', 'disabled', expirationInDays);
    }

    document.documentElement.classList.toggle('dark-theme');
    document.body.classList.toggle('dark-theme');
};

export default switchTheme;
