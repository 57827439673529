enum ChildBoxTypeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

enum MenuTypeEnum {
    ITEM = 'item',
    GRID = 'grid',
    ARTICLE = 'article',
    REVIEW = 'review',
    COUPON = 'coupon',
    IMAGE = 'image',
    DIAGRAM = 'diagram',
    DETAIL = 'detail',
    COMPARE = 'compare',
    BUY_PAGE = 'buy-page',
    HEADING = 'heading',
    BUTTON = 'button',
}

enum MenuBadgesEnum {
    COMING_SOON = 'coming-soon',
    EARN = 'earn',
    EXCLUSIVE = 'exclusive',
    FREE = 'free',
    HOT = 'hot',
    REWARDS = 'rewards',
    TOP = 'top',
    TRENDING = 'trending',
}

enum MenuIconTypeEnum {
    LIST = 'list',
}

enum ArticleSubtypeEnum {
    DEFAULT = 'default',
    YOUTUBE = 'youtube',
}

enum ButtonSubtypeEnum {
    DEFAULT = 'default',
    CODE = 'code',
    SUBSCRIBE = 'subscribe',
}

enum HideOptionEnum {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

enum NavigationActionsEnum {
    SUBSCRIBE_MODAL = 'subscribe_modal',
    NEWSLETTER_MODAL = 'newsletter_modal',
}

enum LocalesEnum {
    EN = 'en',
    FR = 'fr',
    ES = 'es',
    RU = 'ru',
    TR = 'tr',
    ID = 'id',
    BR = 'br',
    CN = 'cn',
    VN = 'vn',
    JP = 'jp',
}

export {
    ChildBoxTypeEnum,
    MenuTypeEnum,
    MenuBadgesEnum,
    MenuIconTypeEnum,
    ArticleSubtypeEnum,
    ButtonSubtypeEnum,
    HideOptionEnum,
    NavigationActionsEnum,
    LocalesEnum,
};
