<template>
    <div id="currency-select"
         :class="{ 'short-version': isShort}">
        <div class="info">
            <div class="d-flex justify-content-center align-items-center position-relative mb-2">
                <SearchIcon />
                <input v-model="search"
                       :placeholder="getTrans('header.search', 'Search')"
                       class="mb-0">
            </div>
            <div class="d-flex flex-row align-items-center view-selector">
                <button v-for="(tab, index) in tabs"
                        :key="tab.name"
                        class="d-flex justify-content-center align-items-center btn w-50"
                        :class="{ active: tab.isActive }"
                        @click="changeCurrencyLanguage(index)">
                    <span>
                        <Translate :translationKey="`header.${tab.name}`"
                                   :fallback="tab.fallBack" />
                    </span>
                </button>
            </div>
            <div class="d-flex scroll"
                 :class="{ 'overflow-y-scroll': tabs[1].isActive }">
                <div v-if="tabs[0].isActive"
                     class="w-100 languages">
                    <div v-if="filteredLanguages.length > 0">
                        <a v-for="language in filteredLanguages"
                           :key="language.code"
                           class="language"
                           :class="{ active: currentLocale === language.key }"
                           :href="currentRouteUrls[language.key]"
                           rel="nofollow noindex">
                            <div class="flag-name">
                                <img class="mr-2"
                                     :src="asset_cdn(language.logo)"
                                     loading="lazy"
                                     :alt="language.name"> {{ language.name }}
                            </div>
                            <div class="country-initials text-uppercase">
                                {{ language.code }}
                            </div>
                        </a>
                    </div>
                    <div v-else
                         class="no-results-message mb-3">
                        <Translate translationKey="header.searchNoResults" />
                    </div>
                </div>
                <div v-if="tabs[1].isActive"
                     class="w-100 currencies">
                    <div v-if="filteredCurrencies.length > 0">
                        <a v-for="currency in filteredCurrencies"
                           :key="currency.name"
                           class="language"
                           :class="{ active: currentCurrency.currency_code === currency.currency_code }"
                           :href="'?currency=' + currency.currency_code"
                           rel="nofollow noindex">
                            <div class="flag-name">
                                <img class="mr-2"
                                     :src="asset_cdn(currency.logo)"
                                     :alt="currency.name"> {{ currency.name }}
                            </div>
                            <div class="country-initials">
                                {{ currency.currency_code }} - {{ currency.sign }}
                            </div>
                        </a>
                    </div>
                    <div v-else
                         class="no-results-message">
                        <Translate translationKey="header.searchNoResults" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { getTrans } from '@/js/helpers/getTrans';
    import { asset_cdn } from '@/js/helpers/helpers';
    import SearchIcon from '../../components/icons/SearchIcon.vue';
    import { ICurrencyLanguageSearch, IFiatCurrency } from '../menu/types/menuFooterTypes';

    interface ICurrencyLanguageSearchProps extends ICurrencyLanguageSearch {
        isShort: boolean,
    }

    const props = defineProps<ICurrencyLanguageSearchProps>();

    const search = ref('');
    const localeValues = ref([]);
    const tabs = ref([
        {
            name: 'language',
            fallBack: 'Language',
            isActive: true,
        },
        {
            name: 'fiatCurrency',
            fallBack: 'Fiat Currency',
            isActive: false,
        },
    ]);

    const searchValue = computed(() => search.value.toLowerCase());

    const filteredCurrencies = computed(() => props.currencies.filter((currency: IFiatCurrency) => currency.name.toLowerCase().includes(searchValue.value)
        || currency.currency_code.toLowerCase().includes(searchValue.value)));

    const filteredLanguages = computed(() => localeValues.value.filter((language) => ((language.name.toLowerCase().includes(searchValue.value)
        || language.code.toLowerCase().includes(searchValue.value))
        && props.currentRouteUrls[language.key])));

    const changeCurrencyLanguage = (selectedIndex) => {
        search.value = '';
        tabs.value.forEach((tab, index) => {
            tab.isActive = (index === selectedIndex);
        });
    };

    onMounted(() => {
        if (props.locales) {
            const languageKeys = Object.keys(props.locales);
            localeValues.value = Object.values(props.locales);

            languageKeys.forEach((value, index) => {
                localeValues.value[index].key = value;
            });
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/partials/currency-select";
</style>
